import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
  useMemo,
} from "react";
import { ContextAPI } from "./animedb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faCirclePlay,
  faCalendarPlus,
  faPlusCircle,
  faHeartCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router";
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from "firebase/firestore";
import { db } from "./firebase.config";
import swal from "sweetalert";
import { LazyLoadImage } from "react-lazy-load-image-component";

const TopAnime = React.memo(({ user, setOpen }) => {
  const data = useContext(ContextAPI);
  const location = useLocation();
  const navigate = useNavigate();
  const refHover = useRef([]);
  const refWatchList = useRef([]);
  const refHiddenIcons = useRef([]);
  console.log("top");
  useEffect(() => {
    window.addEventListener("scroll", data.findWindowScroll);
    return () => {
      window.removeEventListener("scroll", data.findWindowScroll);
    };
  });
  const [visible, setVisible] = useState(10);
  const addToWatchList = useCallback(
    async (user, data, title, mode) => {
      if (user) {
        const watchListDoc = doc(db, "watchingList", user.uid);
        const userWatchingDoc = doc(db, "user", user.uid);
        const existingDoc = await getDoc(watchListDoc);
        const existingUserWatchingDoc = await getDoc(userWatchingDoc);
        if (existingDoc.exists()) {
          try {
            await updateDoc(watchListDoc, {
              [mode]: arrayUnion({ title: title, data }),
            });
            swal({
              title: `${title} has been added to ${mode} list`,
              icon: "success",
            });
          } catch (e) {
            swal({ title: e, icon: "error" });
          }
        } else {
          try {
            await setDoc(watchListDoc, {
              [mode]: [{ title: title, data }],
            });
            swal({
              title: `${title} has been added to ${mode} list`,

              icon: "success",
            });
          } catch (e) {
            swal({ title: e });
          }
        }
        if (existingUserWatchingDoc.exists()) {
          await updateDoc(userWatchingDoc, {
            watchingList: arrayUnion({ title: title, data }),
          });
        } else {
          await setDoc(userWatchingDoc, {
            watchingList: [{ title: title, data }],
          });
        }
      } else {
        setOpen(true);
      }
    },
    [user]
  );
  const DisplayTopAnime = useMemo(
    () => () => {
      return (
        <>
          <div
            onClick={() => {
              if (visible !== 10) {
                data.handleLoad(10, setVisible, "decrease");
              }
            }}
            style={{
              display: "inline-flex",
              justifyContent: "flex-end",
              background: "aquamarine",
              padding: "5px 10px",
              color: "black",
              borderRadius: "15px",
              cursor: "pointer",
              position: "relative",
              left: location.pathname === "/top-anime" ? "95%" : "50%",
              top: location.pathname === "/top-anime" && "30px",
              transform:
                location.pathname === "/top-anime"
                  ? "translate(-70%, 0)"
                  : "translate(-70%, -30%)",
              fontSize: "10px",
            }}
          >
            <h2>Show Less</h2>
          </div>
          <div
            className="collections"
            style={{
              marginTop: location.pathname === "/top-anime" ? "80px" : "20px",
            }}
          >
            {data.topAnime.slice(0, visible).map((ele, index) => {
              const {
                mal_id,
                image_url,
                title,
                rank,
                type,
                episodes,
                score,
                title_english,
                // duration,
              } = ele;
              return (
                <div
                  onClick={(e) => {
                    navigate(`/details/${mal_id}`, {
                      state: {
                        result: ele,
                        animeTitle: title,
                        animeTitleEnglish: title_english ? title_english : "",
                      },
                    });
                  }}
                  id="watch_custom_recommendations_card"
                  style={{ height: "310px" }}
                >
                  <div
                    className="img_container"
                    onMouseEnter={() => {
                      refHover.current[index].style.zIndex = "1";
                      refHover.current[index].style.height = "100%";
                      refWatchList.current[index].style.opacity = "1";
                    }}
                    onMouseLeave={() => {
                      refWatchList.current[index].style.opacity = "0";
                      const hide = new Promise(() => {
                        setTimeout(
                          (refHover.current[index].style.zIndex = "-1"),
                          500
                        );
                      });

                      const shrink = new Promise(() => {
                        setTimeout(
                          (refHover.current[index].style.height = "0"),
                          0
                        );
                      });

                      Promise.all([hide, shrink]);
                    }}
                  >
                    <div
                      className="playButton_container"
                      ref={(el) => (refHover.current[index] = el)}
                      onClick={() =>
                        navigate(`/details/${mal_id}`, {
                          state: {
                            animeTitle: title,
                            animeTitleEnglish: title_english
                              ? title_english
                              : "",
                          },
                        })
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        className="playButton"
                        onClick={() =>
                          navigate(`/details/${mal_id}`, {
                            state: {
                              animeTitle: title,
                              animeTitleEnglish: title_english
                                ? title_english
                                : "",
                            },
                          })
                        }
                      />
                    </div>
                    <div
                      ref={(elem) => (refWatchList.current[index] = elem)}
                      style={{
                        opacity: "0",
                        display: "flex",
                        flexDirection: "column",
                      }}
                      className="add_watchlist_wrapper"
                      onMouseEnter={() => {
                        refHiddenIcons.current[index].style.opacity = "1";
                      }}
                      onMouseLeave={() => {
                        refHiddenIcons.current[index].style.opacity = "0";
                      }}
                    >
                      <div
                        className="hidden_icons"
                        ref={(ele) => (refHiddenIcons.current[index] = ele)}
                        style={{
                          zIndex: "9",
                          opacity: "0",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <FontAwesomeIcon
                          style={{
                            zIndex: "9",
                          }}
                          icon={faHeartCirclePlus}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(user, ele, title, "favorites");
                            e.stopPropagation();
                          }}
                        />
                        <FontAwesomeIcon
                          style={{
                            zIndex: "9",
                          }}
                          icon={faCirclePlay}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(user, ele, title, "watching");
                            e.stopPropagation();
                          }}
                        />
                        <FontAwesomeIcon
                          style={{
                            zIndex: "9",
                          }}
                          icon={faCalendarPlus}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(
                              data.user,
                              ele,
                              title,
                              "plan_to_watch"
                            );
                            e.stopPropagation();
                          }}
                        />
                      </div>
                      <FontAwesomeIcon
                        style={{
                          zIndex: "9",
                        }}
                        icon={faPlusCircle}
                        className="add_watch_list_icons"
                      />
                    </div>

                    {/* ------Score------ */}
                    <div
                      style={{
                        position: "absolute",
                        left: "7px",
                        top: "5px",
                        fontSize: "12px",
                        fontWeight: "bolder",
                        color: "#0f0f0f",
                        borderRadius: "10px",
                        padding: "3px 5px",
                        zIndex: "3",
                        display: "flex",
                        alignItems: "center",
                        background: "aquamarine",
                      }}
                    >
                      Score {score}
                    </div>
                    {/* ------Episode------ */}
                    <div className="custom_eps_container">
                      {episodes != null ? `${episodes} Eps` : `? Eps`}
                    </div>
                    {/* ------Img------ */}
                    <LazyLoadImage
                      width="100%"
                      height="210px"
                      className="recommendations_img"
                      src={image_url}
                      style={{ borderRadius: "0" }}
                      alt="anime image"
                    />
                  </div>
                  <h1
                    className="recommendations_title"
                    style={{
                      color: "whitesmoke",
                      paddingInline: "5px",
                      textAlign: title.length > 25 ? "left" : "center",
                    }}
                  >
                    {title.length > 30 ? `${title.slice(0, 30)}...` : title}{" "}
                    <span
                      style={{
                        fontSize: "15px",
                        color: "#9b9b9b",
                      }}
                    >
                      ({type && type})
                    </span>
                  </h1>
                  {/* ------Rank------ */}
                  <div
                    style={{
                      fontWeight: "bolder",
                      display: "inline-flex",
                      width: "fit-content",
                      padding:
                        rank.toString().length > 1 ? "0px 10px" : "0px 15px",
                      margin: "0 auto 10px",
                      alignItems: "center",
                      borderRadius: "50%",
                      fontSize: "10px",
                      outline: "1px solid #909090",
                      color: "#A8A8A8",
                    }}
                  >
                    <h1>{rank}</h1>
                  </div>
                </div>
              );
            })}
          </div>
          <FontAwesomeIcon
            id={data.showcase}
            icon={faChevronCircleUp}
            onClick={() => window.scrollTo(0, 0)}
          />
          {visible < data.topAnime.length && (
            <button
              id="load_more"
              onClick={() => data.handleLoad(10, setVisible, "increase")}
              style={{
                position: "relative",
                left: location.pathname === "/top-anime" && "50%",
                transform:
                  location.pathname === "/top-anime" && "translateX(-50%)",
              }}
            >
              Load more
            </button>
          )}
        </>
      );
    },
    [data.topAnime, visible, user]
  );
  if (data.isLoading) {
    return <h1>Loading...</h1>;
  }
  if (data.caughtError) {
    return <h1>{data.caughtError}</h1>;
  }
  return <DisplayTopAnime />;
});
export default TopAnime;
