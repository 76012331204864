import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {getFirestore} from '@firebase/firestore';
const firebaseConfig = {
    apiKey: "AIzaSyBbLaRgImXecBw2cL_1Gl4nk36Ne4JKUpU",
    authDomain: "yume-a72a6.firebaseapp.com",
    projectId: "yume-a72a6",
    storageBucket: "yume-a72a6.appspot.com",
    messagingSenderId: "789084697063",
    appId: "1:789084697063:web:f53777e8f3a8f922828d42",
    measurementId: "G-ZCSV19GDEY"
}
export const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
