import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { ContextAPI } from "./animedb";
import "./animedb.css";
import { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { getAuth } from "firebase/auth";
import { useRef } from "react";
import { doc, onSnapshot } from "firebase/firestore";
import { db, app } from "./firebase.config";
import SearchHint from "./SearchHint";

function NavBar({ user, setOpen }) {
  const data = useContext(ContextAPI);
  const navigate = useNavigate();
  const [avatar, setAvatar] = useState();
  const location = useLocation();
  const searchBoxRef = useRef();
  const handleSubmit = (event) => {
    event.preventDefault();
    data.handleSearch(event);
    navigate("/search", { state: { searchList: data.searchList } });
    data.searchHintRef.current.style.display = "none";
  };
  const getAvatar = async () => {
    if (user) {
      const userDoc = doc(db, "user_avatar", user.uid);
      onSnapshot(userDoc, (snapshot) => {
        const avatarNumber = snapshot.data().avatar_number;
        const avatarImg = snapshot.data().avatar_img;
        const user_avatar = avatarNumber
          ? require(`./img/profile/profile${avatarNumber}.jpeg`)
          : avatarImg;
        setAvatar(user_avatar);
      });
    } else {
      setAvatar(require(`./img/default_avatar.webp`));
    }
  };
  const openAuthenticationWindow = () => {
    if (user) {
      navigate("/user/profile");
    } else {
      setOpen(true);
    }
  };
  const handleSignOut = (e) => {
    e.stopPropagation();
    getAuth(app).signOut();
  };
  useEffect(() => {
    getAvatar();
  }, [user]);
  const hideSearchHintBox = (e) => {
    const isClickedInside = searchBoxRef.current.contains(e.target);
    if (!isClickedInside && data.searchHintRef.current) {
      data.searchHintRef.current.style.display = "none";
    }
  };
  useEffect(() => {
    window.addEventListener("click", hideSearchHintBox);
    return () => {
      window.removeEventListener("click", hideSearchHintBox);
    };
  }, []);
  return (
    <>
      <header>
        <FontAwesomeIcon
          id="menu_icon"
          icon={faBars}
          onClick={data.handleNavBar}
        />
        <Link to="/">
          <div className="nav_logo_wrapper">
            <div className="nav_logo_container">
              <img
                width="27px"
                height="27px"
                src={require("./img/animedia_logo.webp")}
                alt="animedia page image"
              />
            </div>
            <h1 style={{ fontSize: "20px" }}>ANIMEDIA</h1>
          </div>
        </Link>

        <div className="login_container" onClick={openAuthenticationWindow}>
          <img
            width="30px"
            height="30px"
            src={avatar}
            alt="user avatar"
            onClick={openAuthenticationWindow}
          />
          <h3
            className="username"
            style={{ color: user ? "whitesmoke" : "#313131", margin: "auto" }}
          >
            {user ? "" : "Login"}
          </h3>
          {user && (
            <FontAwesomeIcon
              icon={faRightFromBracket}
              id="menu_icon_signout"
              onClick={handleSignOut}
            />
          )}
        </div>
      </header>
      <div className={data.navBar}>
        <div className="navbar_button_container">
          <button
            className="navbar_button"
            onClick={() => {
              navigate("/top-anime");
            }}
          >
            Top Anime List
          </button>

          <Link to="/search-by-genres">
            <button className="navbar_button">Search by Genres</button>
          </Link>
          <Link to="/search-by-image">
            <button className="navbar_button">Search by Image</button>
          </Link>
        </div>
        <div
          ref={searchBoxRef}
          className="searchHint_box"
          style={{
            width: "100%",
            height: "auto",
            position: "relative",
            zIndex: "10",
            display: "grid",
            placeItems: "center",
          }}
        >
          <form
            className="navbar_form"
            onSubmit={handleSubmit}
            style={{ marginBottom: "20px", width: "100%" }}
          >
            <input
              style={{
                display: "block",
                width: "100%",
                borderRadius: "5px",
                height: "50px",
              }}
              type="search"
              placeholder="Search Anime here"
              value={data.searchAnime}
              onFocus={() => {
                data.searchHintRef.current.style.display = "block";
              }}
              onInput={(e) => {
                data.setSearchAnime(e.target.value);
                if (e.target.value.length > 3) {
                  data.FindSearchAnime();
                }
              }}
            />
          </form>
          {location.pathname !== "/search" && data.searchAnime.length > 3 && (
            <SearchHint />
          )}
        </div>
      </div>
    </>
  );
}
export default NavBar;
