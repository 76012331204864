import React, {
  useState,
  useContext,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useNavigate } from "react-router";
import { ContextAPI } from "./animedb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faCirclePlay,
  faClock,
  faAngleRight,
  faAngleLeft,
  faFilm,
  faFire,
  faCheckCircle,
  faCalendarPlus,
  faCircle,
  faPlusCircle,
  faHeartCirclePlus,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import PendingIcon from "@mui/icons-material/Pending";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  doc,
  collection,
  getDoc,
  updateDoc,
  setDoc,
  arrayUnion,
} from "firebase/firestore";
import { db } from "./firebase.config";
import swal from "sweetalert";
import {
  LazyLoadComponent,
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
import TopAnime from "./topanime";
import CurrentLyReleasedEp from "./CurrentlyReleasedEp";
import { useMediaQuery } from "react-responsive";
import "./watch.css";
function AnimeDisplay({ user, setOpen }) {
  const [swiper, setSwiper] = useState(null);
  const refHover = useRef([]);
  const refHiddenIcons = useRef([]);
  const refWatchList = useRef([]);
  const data = useContext(ContextAPI);
  const navigate = useNavigate();
  const [visible, setVisible] = useState(10);
  const watchListCollection = collection(db, "watchList");
  const mobile_screen = useMediaQuery({ query: "(max-width: 768px)" });
  const addToWatchList = useCallback(
    async (user, data, title, mode) => {
      if (user) {
        const watchListDoc = doc(watchListCollection, user.uid);
        const userWatchingDoc = doc(db, "user", user.uid);
        const existingDoc = await getDoc(watchListDoc);
        const existingUserWatchingDoc = await getDoc(userWatchingDoc);
        if (existingDoc.exists()) {
          try {
            await updateDoc(watchListDoc, {
              [mode]: arrayUnion({ title: title, data }),
            });
            swal({
              title: `${title} has been added to ${mode} list`,

              icon: "success",
            });
          } catch (e) {
            swal({ title: e, icon: "error" });
          }
        } else {
          try {
            await setDoc(watchListDoc, {
              [mode]: [{ title: title, data }],
            });
            swal({
              title: `${title} has been added to ${mode} list`,

              icon: "success",
            });
          } catch (e) {
            swal({ title: e });
          }
        }
        if (existingUserWatchingDoc.exists()) {
          await updateDoc(userWatchingDoc, {
            watchingList: arrayUnion({ title: title, data }),
          });
        } else {
          await setDoc(userWatchingDoc, {
            watchingList: [{ title: title, data }],
          });
        }
      } else {
        setOpen(true);
      }
    },
    [user]
  );
  const anime = data.randomQuote && data.randomQuote.anime;
  const character = data.randomQuote && data.randomQuote.character;
  const quote = data.randomQuote && data.randomQuote.quote;
  const navigate_to_detail = useCallback(
    async (mal_id, ele, title, title_english) => {
      navigate(`/details/${mal_id}`, {
        state: {
          result: ele,
          animeTitle: title,
          animeTitleEnglish: title_english ? title_english : "",
        },
      });
    }
  );
  useEffect(() => {
    window.addEventListener("scroll", data.findWindowScroll);
    return () => {
      window.removeEventListener("scroll", data.findWindowScroll);
    };
  }, []);
  // const info = data.randomInfo;
  // const handleNavigate = useCallback(() => {
  //   if (info && info.Character.media.nodes[0].idMal) {
  //     navigate(`/details/${info.Character.media.nodes[0].idMal}`, {
  //       state: {
  //         animeTitle: info.Character.media.nodes[0].title.romaji,
  //         animeTitleEnglish: info.Character.media.nodes[0].title.english,
  //       },
  //     });
  //   }
  // }, [info]);
  const AiringAnime = useMemo(
    () => () => {
      return (
        <>
          <div className="category_wrapper airing">
            <p className="category_title">
              <strong>Airing</strong>
            </p>
            <FontAwesomeIcon icon={faFilm} className="category_icon" />
          </div>
          <div
            className="show_less_button"
            onClick={() => {
              if (visible !== 10) {
                data.handleLoad(10, setVisible, "decrease");
              }
            }}
          >
            <h2>Show Less</h2>
          </div>
          <div className="collections">
            {data.animeList.slice(0, visible).map((ele, index) => {
              const { mal_id, title, episodes, genres, title_english } = ele;
              return (
                <div
                  key={`airing_${mal_id}`}
                  onClick={() =>
                    navigate_to_detail(mal_id, ele, title, title_english)
                  }
                  id="watch_custom_recommendations_card"
                >
                  <div
                    className="img_container"
                    onMouseEnter={() => {
                      refHover.current[index].style.zIndex = "1";
                      refHover.current[index].style.height = "100%";
                      refWatchList.current[index].style.opacity = "1";
                    }}
                    onMouseLeave={() => {
                      refWatchList.current[index].style.opacity = "0";
                      refHover.current[index].style.zIndex = "-1";
                      refHover.current[index].style.height = "0";
                    }}
                  >
                    <div
                      className="playButton_container"
                      ref={(el) => (refHover.current[index] = el)}
                      onClick={() =>
                        navigate_to_detail(mal_id, ele, title, title_english)
                      }
                    >
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        className="playButton"
                        onClick={() =>
                          navigate_to_detail(mal_id, ele, title, title_english)
                        }
                      />
                    </div>
                    <div
                      ref={(elem) => (refWatchList.current[index] = elem)}
                      className="add_watchlist_wrapper"
                      onMouseEnter={() => {
                        refHiddenIcons.current[index].style.opacity = "1";
                      }}
                      onMouseLeave={() => {
                        refHiddenIcons.current[index].style.opacity = "0";
                      }}
                    >
                      <div
                        className="hidden_icons"
                        ref={(ele) => (refHiddenIcons.current[index] = ele)}
                      >
                        <FontAwesomeIcon
                          icon={faHeartCirclePlus}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(user, ele, ele.title, "favorites");
                            e.stopPropagation();
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faCirclePlay}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(user, ele, ele.title, "watching");
                            e.stopPropagation();
                          }}
                        />
                        <FontAwesomeIcon
                          icon={faCalendarPlus}
                          className="add_watch_list_icons "
                          onClick={(e) => {
                            addToWatchList(
                              user,
                              ele,
                              ele.title,
                              "plan_to_watch"
                            );
                            e.stopPropagation();
                          }}
                        />
                      </div>
                      <FontAwesomeIcon
                        icon={faPlusCircle}
                        className="add_watch_list_icons"
                      />
                    </div>
                    <div
                      className="status_container"
                      style={{
                        background: `${
                          ele && ele.status === "FINISHED"
                            ? "aquamarine"
                            : "rgb(236, 236, 130)"
                        }`,
                      }}
                    >
                      Airing
                      {ele && ele.status === "FINISHED" ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          id="circle_icon"
                        />
                      ) : (
                        <PendingIcon id="pending_icon" />
                      )}
                    </div>

                    <div className="custom_eps_container">
                      {episodes != null ? `${episodes} Eps` : `? Eps`}
                    </div>
                    <LazyLoadImage
                      width="100%"
                      height="210px"
                      className="recommendations_img"
                      src={ele && ele.images.webp.image_url}
                      alt="anime image"
                    />
                  </div>
                  <p
                    className="recommendations_title"
                    style={{
                      paddingInline: "5px",
                      textAlign: ele.title.length > 25 ? "left" : "center",
                    }}
                  >
                    {ele.title.length > 30
                      ? `${ele.title.slice(0, 30)}...`
                      : ele.title}
                  </p>
                  <div className="format_container">
                    <p id="format">{ele && ele.type}</p>
                    <FontAwesomeIcon icon={faCircle} id="circle_icon" />
                    <p>{ele && ele.duration}</p>
                  </div>
                </div>
              );
            })}
          </div>
          {visible < data.animeList.length && (
            <button
              type="button"
              title="load more"
              id="load_more"
              onClick={() => {
                data.handleLoad(20, setVisible, "increase");
              }}
            >
              Load more
            </button>
          )}
          <FontAwesomeIcon
            id={data.showcase}
            icon={faChevronCircleUp}
            onClick={data.handleWindow}
          />
        </>
      );
    },
    [visible, data.animeList, user]
  );
  const RandomQuote = useMemo(
    () => () => {
      if (data.randomQuote) {
        return (
          <div
            // style={{
            //   cursor:
            //     info && info.Character && info.Character.media.nodes[0].idMal
            //       ? "pointer"
            //       : "default",
            // }}
            className="random_quote_fact"
            // onClick={handleNavigate}
          >
            <p id="random_quote_title">
              <strong>Random Quote</strong>
            </p>
            {/* {info &&
              info.Character &&
              info.Character.media.nodes[0].bannerImage && (
                <div
                  id="anime_img"
                  style={{
                    position: "relative",
                    zIndex: 2,
                    backgroundImage: `url(${info.Character.media.nodes[0].bannerImage})`,
                  }}
                />
              )} */}
            <div className="quote_container">
              {/* {info && info.Character && info.Character.image.medium && (
                <img
                  width="28%"
                  height="200px"
                  id="character_img"
                  src={`${info.Character.image.medium}?quality=auto&format=webp`}
                  alt="character image"
                />
              )} */}
              <div className="quote_info">
                <p id="random_quote_anime">
                  <strong>{anime && anime}</strong>
                </p>
                <p id="random_quote_character">
                  <strong>{character && character}: </strong>"{quote && quote}"
                </p>
              </div>
            </div>
            {/* {info &&
              info.Character &&
              info.Character.media.nodes[0].description && (
                <p>
                  <strong>Brief synopsis:</strong>{" "}
                  {`${info.Character.media.nodes[0].description
                    .replace("Source: Aniplex USA)", "")
                    .replace("(Source: MAL Rewrite)", "")
                    .replace("(Source: Anime News Network)", "")
                    .replace(/\W+/g, " ")
                    .replace(/\-$/, "")
                    .replace("<i>", "")
                    .replace("</i>", "")
                    .slice(
                      0,
                      info.Character.media.nodes[0].description.length > 350
                        ? 350
                        : info.Character.media.nodes[0].description.length
                    )}...`}
                </p>
              )} */}
            {/* {info &&
              info.Character &&
              info.Character.media.nodes[0].episodes && (
                <p id="ep">{info.Character.media.nodes[0].episodes} Eps</p>
              )} */}
          </div>
        );
      }
    },
    [data.randomQuote]
  );
  if (!data.animeList) {
    return (
      <img
        width="200px"
        height="170px"
        alt="loading gif"
        className={`gif ${data.gif}`}
        src={require("./img/loading.gif")}
      />
    );
  }
  if (data.showCaughtError) {
    return (
      <p>
        <strong>{data.showCaughtError}</strong>
      </p>
    );
  }
  return (
    <div className="display_page_wrapper">
      {/* -------------------Helmet------------------------ */}
      <Helmet>
        <link rel="canonical" href="https://animedia.me" />
        <title>Animedia - Watch Anime Free Online</title>
        <meta name="robots" content="index, follow, archive" />
        <meta name="title" content="Animedia - Free Streaming Website"></meta>
        <meta
          name="description"
          content="Animedia is a free anime streaming website offering millions latest (sub & dub) animes with ZERO Subscriptions Needed! No ads and No Pop-ups. Join Now!"
        ></meta>
        <meta
          name="keywords"
          content=" free anime, animedia, watch anime online, watch anime, anime watching, anime, anime streaming, watch anime for free, watch anime free, streaming anime free, animedia watch anime free, animedia watch anime, animedia watch anime for free, animedia watch anime, animedia stream anime, animedia streaming anime, watch anime online, Animedia watch anime, Animedia watch anime free, Animedia watch anime for free, Animedia streaming anime online"
        />
        <meta property="og:title" content="Animedia - Streaming Anime Free" />
        <meta property="og:url" content="https://animedia.me" />
        <meta property="og:image" content={require("./img/animedia.webp")} />
        <meta property="og:site_name" content="animedia.me" />
        <meta
          property="og:description"
          content="animedia is a free anime streaming website offering millions latest (sub & dub) animes with ZERO Subscriptions Needed! No ads and No Pop-ups. Join Now!"
        />
        <meta property="og:type" content="website" />
        <meta
          name="twitter:card"
          content="Animedia - a FREE streaming anime platform"
        />
        <meta name="twitter:site" content="https://animedia.me" />
        <meta name="twitter:title" content="Animedia - Watch Anime Free" />
        <meta
          name="twitter:description"
          content="animedia is a free anime streaming website offering millions latest (sub & dub) animes with ZERO Subscriptions Needed! No ads and No Pop-ups. Join Now!"
        />
        <meta name="twitter:image" content={require("./img/animedia.webp")} />
      </Helmet>
      {/* -------------------Swiper------------------------ */}
      <Swiper
        className="trending_swiper"
        modules={[Pagination, Autoplay]}
        slidesPerView={1}
        autoplay={{ delay: 3000, disableOnInteraction: true }}
        spaceBetween={0}
        grabCursor={true}
        pagination={{ clickable: true }}
        onInit={(el) => setSwiper(el)}
      >
        <div className="navigation_container">
          <button
            type="button"
            title="navigate to previous slide"
            className="navigation"
            onClick={() => swiper.slidePrev()}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </button>
          <button
            type="button"
            title="navigate to next slide"
            className="navigation"
            onClick={() => swiper.slideNext()}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </button>
        </div>
        {data.trendingList.map((ele, sliderIndex) => {
          const { title, idMal, description } = ele.media;
          const getDisplayedText = (max_number) => {
            const originalTitle = title.romaji;
            const displayed_title =
              originalTitle.length > max_number
                ? originalTitle.slice(0, max_number) + "..."
                : originalTitle;
            return displayed_title;
          };
          const abbreviate_title = title.romaji
            .toLowerCase()
            .replace("×", "-x-")
            .replace("season 2", "2nd season")
            .replace("season 3", "3rd season")
            .replace("season 4", "4th season")
            .replace("season 5", "5th season")
            .replace("season 6", "6th season")
            .replace("season 7", "7th season")
            .replace("Ψ", "ps")
            .replace(/\W+/g, "-")
            .replace(/\-$/, "")
            .replace("'", "")
            .replace(":", "")
            .replace("/", "");
          return (
            <SwiperSlide
              key={`trending_${ele.media.idMal}`}
              className="trending_card"
            >
              <div className="trending_card">
                <LazyLoadImage
                  width="100%"
                  height="100%"
                  id="trending_img"
                  src={`${ele.media.bannerImage}?quality=auto&format=webp`}
                  alt="anime image"
                />
                <div className="trending_info_container">
                  <h2 className="trending_title">{getDisplayedText(30)}</h2>
                  <div className="trending_info">
                    <div className="trending_text">
                      <FontAwesomeIcon
                        icon={faCirclePlay}
                        id="circle_play_icon"
                      />
                      {ele.media.format}
                    </div>
                    <div className="trending_text">
                      <FontAwesomeIcon id="circle_play_icon" icon={faClock} />
                      {ele.media.duration}m
                    </div>
                  </div>
                  <div className="genre_container">
                    {ele.media.genres.map((elem) => {
                      return (
                        <div key={`genress_${elem}`} className="genre">
                          {elem}
                        </div>
                      );
                    })}
                  </div>
                  <p>
                    {`${description
                      .replace("<i>", "")
                      .replace("</i>", "")
                      .replace("<br>", "")
                      .replace("br", "")
                      .replace("Source: Aniplex USA)", "")
                      .replace("(Source: MAL Rewrite)", "")
                      .replace("(Source: Anime News Network)", "")
                      .replace(/\W+/g, " ")
                      .replace(/\-$/, "")
                      .slice(
                        0,
                        description.length > 120 ? 120 : description.length
                      )}...`}
                  </p>
                  <div className="trending_buttons_container">
                    <button
                      title="navigate to watch"
                      type="button"
                      className="watch_trending"
                      onClick={() => {
                        navigate(`/watch/${abbreviate_title}/${idMal}`);
                      }}
                    >
                      <FontAwesomeIcon icon={faCirclePlay} /> Watch Now
                    </button>
                    <Link
                      to={`/details/${idMal}`}
                      state={{
                        animeTitle: title.romaji,
                        animeTitleEnglish: title.english ? title.english : "",
                      }}
                      className="details_trending"
                    >
                      Details
                      <FontAwesomeIcon icon={faAngleRight} />
                    </Link>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>

      {/* -------------------Random Quote------------------------ */}
      <RandomQuote />

      {/* ------------------- Collection --------------------- */}
      <AiringAnime />
      {/* ------------------- Top Anime --------------------- */}
      <div className="category_wrapper top">
        <p className="category_title">
          <strong>Top Anime</strong>
        </p>
        <FontAwesomeIcon icon={faFire} className="category_icon" />
      </div>
      <LazyLoadComponent>
        <TopAnime />
      </LazyLoadComponent>
      {/* ------------------- Currently Released Ep --------------------- */}
      <div className="category_wrapper latest_ep">
        <p className="category_title">
          <strong>Latest Episodes</strong>
        </p>
        <FontAwesomeIcon icon={faEye} className="category_icon" />
      </div>
      <LazyLoadComponent>
        <CurrentLyReleasedEp />
      </LazyLoadComponent>
    </div>
  );
}

export default trackWindowScroll(AnimeDisplay);
