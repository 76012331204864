import { useState, useEffect, useContext, useRef } from "react";
import { ContextAPI } from "./animedb";
import { useLocation } from "react-router";
import "./authentication.css";
import CheckIcon from "@mui/icons-material/Check";
import SaveIcon from "@mui/icons-material/Save";
import SkipNextIcon from "@mui/icons-material/SkipNext";
import {
  getAuth,
  updateProfile,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
} from "@firebase/auth";
import { FileUpload, RememberMe } from "@mui/icons-material";
import CollectionsIcon from "@mui/icons-material/Collections";
import { useMediaQuery } from "react-responsive";
import swal from "sweetalert";
import { db } from "./firebase.config";
import { doc, setDoc, getDoc, updateDoc } from "firebase/firestore";
import { app } from "./firebase.config";
const Authentication = ({ setOpen, setIsOpen }) => {
  return (
    <div className="authentication_window">
      <LoginPage setOpen={setOpen} setIsOpen={setIsOpen} />
    </div>
  );
};
export default Authentication;

export const LoginPage = ({ setOpen, setIsOpen }) => {
  const auth = getAuth(app);
  const data = useContext(ContextAPI);
  const location = useLocation();
  const [name, setName] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassWord] = useState("");
  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegister, setIsRegister] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState("");
  const [uniqeIpAddress, setUniqueIpAddress] = useState();
  const rememberMeRef = useRef();
  const desktop_screen = useMediaQuery({ query: "(min-width: 768px)" });
  const medium_screen = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1000px)",
  });
  const login = async () => {
    try {
      await signInWithEmailAndPassword(auth, loginEmail, loginPassword);
      if (RememberMe) {
        localStorage.setItem(
          "user",
          JSON.stringify({
            remember: true,
            email: loginEmail,
            password: loginPassword,
          })
        );
      }
      swal("Login SuccessFully!", "Welcome back Boss", "success", {
        buttons: { ok: true },
      }).then((value) => {
        setOpen(false);
      });
    } catch (e) {
      console.log(e);
      swal({ text: e.message, icon: "error" });
    }
  };
  const register = async () => {
    try {
      await createUserWithEmailAndPassword(
        auth,
        registerEmail,
        registerPassword
      );
      await updateProfile(auth.currentUser, { displayName: name });
      setOpen(false);
      auth.onAuthStateChanged((user) => {
        if (user) {
          setIsOpen(true);
        }
      });
    } catch (e) {
      console.log(e);
      swal({ text: e.message, icon: "error" });
    }
  };

  const ForgotPassword = async () => {
    try {
      await sendPasswordResetEmail(auth, getEmail(), {
        url: `http://localhost:3000/`,
      });

      const docRef = doc(db, "lastVisitedPage", uniqeIpAddress);
      await setDoc(docRef, {
        path_name: location.pathname,
        state: location.state,
      });
      swal({
        title: "Reset Password Successfully!",
        text: "Please Check your email for verification instruction",
      });
    } catch (e) {
      console.log(e);
      swal({ text: e.message, icon: "error" });
    }
  };

  const getIpAddress = async () => {
    const res = await fetch("https://geolocation-db.com/json/");
    const result = await res.json();
    setUniqueIpAddress(result.IPv4);
  };

  useEffect(() => {
    const userCredentials = JSON.parse(localStorage.getItem("user"));
    if (userCredentials) {
      setLoginEmail(userCredentials.email);
      setLoginPassWord(userCredentials.password);
      setRememberMe(userCredentials.remember);
    }
    if (userCredentials && userCredentials.remember === true) {
      rememberMeRef.current.checked = true;
    }
    getIpAddress();
  }, []);
  const getTitle = () => {
    if (isRegister) {
      return "Create an Account";
    } else if (isForgotPassword) {
      return "Reset Password";
    } else return "Welcome to Animedia";
  };
  const getEmail = () => {
    if (isRegister) {
      return registerEmail;
    } else if (isForgotPassword) {
      return forgotPasswordEmail;
    } else {
      return loginEmail;
    }
  };
  const getPassword = () => {
    if (isRegister) {
      return registerPassword;
    } else {
      return loginPassword;
    }
  };
  const getMarginTop = () => {
    if (medium_screen) {
      return "20px auto ";
    } else {
      return "-150px auto 20px ";
    }
  };
  return (
    <div
      className="authentication_wrapper"
      style={{
        height: "100%",
        backgroundImage: `url(${require("./img/rengoku_portrait.webp")})`,
      }}
    >
      {/* <div
        className="sukuna_container"
        style={{ marginTop: !isRegister ? "-175px" : "-320px" }}
      >
        <img
          id="sukuna"
          src={require("./img/sukuna.webp")}
          alt="background image"
        />
      </div> */}
      <div className="login_wrapper">
        <h1>{getTitle()}</h1>
        {isRegister && (
          <div className="input_wrapper">
            <label htmlFor="name">YOUR NAME</label>
            <input
              style={{
                width: "100%",
                borderRadius: "5px",
                height: desktop_screen ? "30px" : "25px",
              }}
              type="text"
              name="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        )}
        <div className="input_wrapper">
          <label htmlFor="email">EMAIL</label>
          <input
            style={{
              width: "100%",
              borderRadius: "5px",
              height: desktop_screen ? "35px" : "25px",
            }}
            type="email"
            name="email"
            value={getEmail()}
            onChange={(e) => {
              if (isRegister) {
                setRegisterEmail(e.target.value);
              } else if (isForgotPassword) {
                setForgotPasswordEmail(e.target.value);
                data.setForgotPassWordEmail(e.target.value);
              } else {
                setLoginEmail(e.target.value);
              }
            }}
          />
        </div>
        {!isForgotPassword && (
          <div className="input_wrapper">
            <label htmlFor="password">PASSWORD</label>
            <input
              style={{
                width: "100%",
                borderRadius: "5px",
                marginBottom: "0px",
                height: desktop_screen ? "35px" : "25px",
              }}
              type="password"
              name="password"
              // value={registerPassword}
              value={getPassword()}
              onChange={(e) => {
                if (isRegister) {
                  setRegisterPassword(e.target.value);
                } else {
                  setLoginPassWord(e.target.value);
                }
              }}
            />
          </div>
        )}
        {isRegister && (
          <div className="input_wrapper">
            <label htmlFor="password">CONFIRM PASSWORD</label>
            <input
              style={{
                width: "100%",
                borderRadius: "5px",
                marginBottom: "0px",
                height: desktop_screen ? "35px" : "25px",
              }}
              type="password"
              name="confirm_password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
        )}
        {/* remember me and forgot password */}
        {!isForgotPassword && (
          <div className="small_features">
            <div className="remember_me">
              <input
                ref={rememberMeRef}
                type="checkbox"
                name="remember_me"
                onChange={() => setRememberMe(!rememberMe)}
                style={{
                  width: "20px",
                  height: "20px",
                  display: "block",
                  margin: "10px 10px 10px 0",
                  cursor: "pointer",
                }}
              />
              <label htmlFor="remember_me">
                <h4
                  id="features_text"
                  onClick={() => {
                    setRememberMe(!rememberMe);
                    rememberMeRef.current.checked = !rememberMe;
                  }}
                >
                  Remember me
                </h4>
              </label>
            </div>
            <h4
              id="features_text"
              style={{ fontSize: desktop_screen ? "15px" : "11px" }}
              onClick={async () => {
                setIsForgotPassword(true);
                setIsRegister(false);
              }}
            >
              Forgot password?
            </h4>
          </div>
        )}
        {!isForgotPassword ? (
          <>
            <button
              style={{ height: !desktop_screen && "30px" }}
              type="submit"
              onClick={!isRegister ? login : register}
              className="login_button"
            >
              {!isRegister ? "Login" : "Sign me up"}
            </button>
            <div
              className="sign_in_with_google_wrapper"
              style={{ height: !desktop_screen && "30px" }}
              onClick={() => {
                setOpen(false);
                data.getUser(isRegister ? "register" : "login");
              }}
            >
              <img
                style={{ height: "100%", width: !desktop_screen && "30px" }}
                src={require("./img/google.webp")}
                alt="google image"
                className="sign_in_with_google_img"
              />
              <div
                className="sign_in_with_google_text"
                style={{
                  fontSize: desktop_screen ? "20px" : "12px",
                  height: "100%",
                }}
              >
                {!isRegister ? "Sign in with Google" : "Continue with Google"}
              </div>
            </div>
          </>
        ) : (
          <button
            type="submit"
            onClick={ForgotPassword}
            className="login_button"
          >
            Submit
          </button>
        )}
        {!isForgotPassword ? (
          <div className="button_wrapper">
            <p style={{ fontSize: desktop_screen ? "20px" : "12px" }}>
              {!isRegister ? "Don't have an account?" : "Have an account?"}{" "}
            </p>
            {!isRegister ? (
              <button
                onClick={() => {
                  setIsRegister(true);
                  setIsForgotPassword(false);
                }}
              >
                Register
              </button>
            ) : (
              <button
                onClick={() => {
                  setIsRegister(false);
                  setIsForgotPassword(false);
                }}
              >
                Log in
              </button>
            )}
          </div>
        ) : (
          <h3
            style={{ textAlign: "center" }}
            onClick={() => {
              setIsForgotPassword(false);
              setIsRegister(false);
            }}
          >
            Back to Sign In Page
          </h3>
        )}
        {/* <ToastContainer
          style={{
            position: "relative",
            bottom: "-80px",
          }}
        /> */}
      </div>
    </div>
  );
};

export const ChooseAvatar = ({
  name,
  user,
  avatarToastRef,
  avatarToast,
  isChangeAvatar,
  isGoogle,
  googleToast,
  setIsOpen,
  setOpen,
  uid,
}) => {
  const images = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const avatarRef = useRef([]);
  const isChosen = useRef([]);
  const checkIconRef = useRef([]);
  const [value, setValue] = useState("our_image");
  const [file, setFile] = useState();
  const [img, setImg] = useState();
  const handleImgFile = () => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImg(e.target.result);
    };
    reader.readAsDataURL(file);
  };
  const xs_screen = useMediaQuery({ query: "(max-width: 300px)" });
  const desktop_screen = useMediaQuery({ query: "(min-width: 768px)" });
  const small_screen = useMediaQuery({
    query: "(min-width: 301px) and (max-width: 361px)",
  });
  const storeUserData = async () => {
    const userDoc = doc(db, "user", uid && uid);
    const existingDoc = await getDoc(userDoc);
    if (!existingDoc.exists()) {
      await setDoc(userDoc, {
        name: name,
        img: img,
        joined:
          user && new Date(user.metadata.createdAt * 1).toLocaleDateString(),
        isPublicWatchList: true,
      });
    } else {
      await updateDoc(userDoc, {
        img: img,
      });
    }
  };
  const handleSubmit = async (img) => {
    try {
      const userAvatarDoc = doc(db, "user_avatar", uid);
      storeUserData();
      console.log(img);
      console.log(typeof img);
      if (typeof img === "number") {
        await setDoc(userAvatarDoc, {
          avatar_number: img,
          is_avatar_number: true,
        });
      } else {
        await setDoc(userAvatarDoc, {
          avatar_img: img,
          is_avatar_number: false,
        });
      }
      if (isGoogle) {
        swal({
          title: `Welcome ${name}`,
          text: "Your Account Has Been Registered Succesfully!",
          icon: "success",
          buttons: { ok: true },
        }).then((value) => {
          setOpen(false);
        });
      }
      if (name) {
        swal({
          title: `Welcome ${name}`,
          text: "Your Account Has Been Registered Succesfully!",
          icon: "success",
          buttons: { ok: true },
        }).then((value) => {
          setIsOpen(false);
        });
      } else {
        swal({
          title: "Avatar Changed Successfully",
          icon: "success",
          buttons: { ok: true },
        }).then((value) => {
          setIsOpen(false);
        });
      }
    } catch (e) {
      console.log(e);
      swal({ title: e.message, icon: "error" });
    }
  };
  const isChangeAvatarStyle = {
    display: "grid",
    placeItems: "center",
  };
  const isNotChangeAvatarStyle = {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  useEffect(() => {
    file && handleImgFile();
  }, [file]);
  const getPastedImage = (e) => {
    const items = e.clipboardData.items;
    //If console.log(items) it would be empty,
    // but when do for loop to traverse it, it will contain something
    const image_address = e.clipboardData.getData("Text");
    if (image_address) {
      setImg(image_address);
    } else {
      for (let i = 0; i < items.length; i++) {
        // Skip content if not image
        if (items[i].type.indexOf("image") === -1) continue;
        // Retrieve image on clipboard as blob
        let imageBlob = items[i].getAsFile();
        // Crossbrowser support for URL
        const URLObj = window.URL || window.webkitURL;
        const image = URLObj.createObjectURL(imageBlob);
        setImg(image);
      }
    }
  };

  if (value === "your_image") {
    window.addEventListener("paste", (e) => getPastedImage(e));
  }
  return (
    <div
      className="avatar_wrapper"
      style={{
        // width:
        //   value === "your_image" &&
        //   (xs_screen ? "115%" : desktop_screen ? "110%" : "125%"),
        width: "100%",
      }}
    >
      <h1
        style={{
          fontSize: desktop_screen ? "30px" : "15px",
          margin: "20px 0",
        }}
      >
        Pick Your Avatar
      </h1>
      <div
        className="avatar_options_wrapper"
        style={{ margin: "0px 0px 20px" }}
      >
        <div
          className="avatar_choice"
          style={{
            color: value === "our_image" ? "aquamarine" : "whitesmoke",
            fontSize: !desktop_screen && "15px",
          }}
          onClick={() => {
            setValue("our_image");
          }}
        >
          Our Images
        </div>
        <div
          className="avatar_choice"
          style={{
            color: value !== "our_image" ? "aquamarine" : "whitesmoke",
            fontSize: !desktop_screen && "15px",
          }}
          onClick={() => {
            setValue("your_image");
          }}
        >
          Your Images
        </div>
      </div>
      {value === "our_image" ? (
        <div className="avatar_container">
          {images.map((ele, index) => {
            const getAvatarWidthAndHeight = () => {
              if (desktop_screen) {
                return "80px";
              } else if (xs_screen) {
                return "50px";
              } else {
                return "70px";
              }
            };
            const getAvatarMargin = () => {
              if (desktop_screen) {
                return "5px 5px 5px 12px";
              } else if (xs_screen || small_screen) {
                return "5px";
              } else {
                return "5px 5px 5px 15px";
              }
            };
            return (
              <div className="avatar_img_container">
                <img
                  onClick={() => {
                    isChosen.current[index] = true;
                    avatarRef.current[index].style.filter = "none";
                    avatarRef.current[index].style.transform = "scale(1.2)";
                    checkIconRef.current[index].style.display = "block";
                    setImg(ele);
                    avatarRef.current.map((ele, position) => {
                      if (ele !== avatarRef.current[index]) {
                        ele.style.filter = "grayscale(130%)";
                        ele.style.transform = "scale(1)";
                        isChosen.current[position] = false;
                        checkIconRef.current[position].style.display = "none";
                      }
                    });
                  }}
                  onMouseEnter={() => {
                    avatarRef.current[index].style.filter = "none";
                    avatarRef.current[index].style.transform = "scale(1.2)";
                  }}
                  onMouseLeave={() => {
                    if (isChosen.current[index] != true) {
                      avatarRef.current[index].style.filter = "grayscale(130%)";
                      avatarRef.current[index].style.transform = "scale(1)";
                    }
                  }}
                  ref={(el) => (avatarRef.current[index] = el)}
                  src={require(`./img/profile/profile${ele}.webp`)}
                  className="avatar_img"
                  alt="user avatar image"
                  style={{
                    width: getAvatarWidthAndHeight(),
                    height: getAvatarWidthAndHeight(),
                    filter: "grayscale(130%)",
                    cursor: "pointer",
                    margin: getAvatarMargin(),
                  }}
                />
                <CheckIcon
                  className="check_icon"
                  style={{ display: "none" }}
                  ref={(item) => (checkIconRef.current[index] = item)}
                />
              </div>
            );
          })}
        </div>
      ) : (
        <div
          className="your_img_box_container"
          style={{ height: xs_screen ? "200px" : "300px" }}
        >
          <CollectionsIcon style={{ width: "50px", height: "50px" }} />
          <label htmlFor="file-upload" className="custom_file_upload_input">
            <FileUpload />{" "}
            <h1 style={{ fontSize: desktop_screen ? "20px" : "13px" }}>
              Upload Image
            </h1>
          </label>
          <input
            className="file_upload_input"
            id="file-upload"
            type="file"
            accept="image/*"
            onChange={(e) => {
              setFile(e.target.files[0]);
            }}
          />
          <h3>Or</h3>
          <div
            className="paste_img_container"
            style={{ fontSize: desktop_screen ? "20px" : "13px" }}
          >
            Paste image or URL <span>ctrl</span> + <span>V</span>
          </div>

          {img && (
            <img
              src={img}
              alt="user avatar image"
              className="your_img_avatar"
            />
          )}
        </div>
      )}
      <div
        className="submit_container"
        style={isChangeAvatar ? isChangeAvatarStyle : isNotChangeAvatarStyle}
      >
        <div
          className="avatar_submit_button"
          onClick={() => {
            handleSubmit(img);
          }}
        >
          <SaveIcon /> Save
        </div>
        {!isChangeAvatar && (
          <div
            className="avatar_submit_button"
            onClick={() => {
              handleSubmit(Math.floor(Math.random() * 14));
            }}
          >
            <SkipNextIcon />
            Skip
          </div>
        )}
      </div>
    </div>
  );
};
