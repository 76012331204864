import React, {
  useContext,
  useEffect,
  useCallback,
  useState,
  useRef,
} from "react";
import { ContextAPI } from "./animedb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faCirclePlay,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router";
import { matchSorter } from "match-sorter";
import { LazyLoadImage } from "react-lazy-load-image-component";
const CurrentLyReleasedEp = React.memo(() => {
  console.log("current");
  const data = useContext(ContextAPI);
  const location = useLocation();
  const navigate = useNavigate();
  const refHover = useRef([]);
  useEffect(() => {
    window.addEventListener("scroll", data.findWindowScroll);
    return () => {
      window.removeEventListener("scroll", data.findWindowScroll);
    };
  });
  const [visible, setVisible] = useState(10);
  const navigateToWatch = useCallback(
    async (animeId, animeTitle, episodeNum) => {
      const res = await fetch(
        `https://api.jikan.moe/v4/anime?q=${animeId.replace("-ova", "")}`
      );
      const result = await res.json();
      const list = matchSorter(
        result.data.filter((ele) => ele.approved === true),
        animeTitle.replace("OVA", ""),
        {
          keys: [
            {
              maxRanking: matchSorter.rankings.STARTS_WITH,
              key: "title",
            },
          ],
        }
      );
      const final_list = list.length > 0 ? list[0] : result.data[0];
      const wachTitle = final_list.title
        .replace("'", "")
        .replace("/", final_list.title.includes("Fate/") ? "-" : "")
        .toLowerCase()
        .replace("Ψ", "ps")
        .replace(/\W+/g, "-")
        .replace(/\-$/, "");
      navigate(`/watch/${wachTitle}/${final_list.mal_id}`, {
        state: {
          isLatestEp: true,
          currentlyReleasedEp: episodeNum,
          jikan: final_list,
        },
      });
    },
    []
  );
  if (data.isLoading) {
    return <h1>Loading...</h1>;
  }
  if (data.caughtError) {
    return <h1>{data.caughtError}</h1>;
  }
  if (data.currentlyReleasedEp) {
    return (
      <>
        <div
          onClick={() => {
            if (visible !== 10) {
              data.handleLoad(10, setVisible, "decrease");
            }
          }}
          style={{
            display: "inline-flex",
            justifyContent: "flex-end",
            background: "aquamarine",
            padding: "5px 10px",
            color: "black",
            borderRadius: "15px",
            cursor: "pointer",
            position: "relative",
            left: location.pathname === "/top-anime" ? "95%" : "50%",
            top: location.pathname === "/top-anime" && "30px",
            transform:
              location.pathname === "/top-anime"
                ? "translate(-70%, 0)"
                : "translate(-70%, -30%)",
            fontSize: "10px",
          }}
        >
          <h2>Show Less</h2>
        </div>
        <div
          className="collections"
          style={{
            marginTop: location.pathname === "/top-anime" ? "80px" : "20px",
          }}
        >
          {data.currentlyReleasedEp.slice(0, visible).map((ele, index) => {
            const { animeId, animeImg, animeTitle, episodeNum } = ele;
            return (
              <div
                onClick={() => navigateToWatch(animeId, animeTitle, episodeNum)}
                id="watch_custom_recommendations_card"
                style={{ height: "310px" }}
              >
                <div
                  className="img_container"
                  onMouseEnter={() => {
                    refHover.current[index].style.zIndex = "1";
                    refHover.current[index].style.height = "100%";
                  }}
                  onMouseLeave={() => {
                    const hide = new Promise(() => {
                      setTimeout(
                        (refHover.current[index].style.zIndex = "-1"),
                        500
                      );
                    });

                    const shrink = new Promise(() => {
                      setTimeout(
                        (refHover.current[index].style.height = "0"),
                        0
                      );
                    });

                    Promise.all([hide, shrink]);
                  }}
                >
                  <div
                    className="playButton_container"
                    ref={(el) => (refHover.current[index] = el)}
                    onClick={() =>
                      navigateToWatch(animeId, animeTitle, episodeNum)
                    }
                  >
                    <FontAwesomeIcon
                      icon={faCirclePlay}
                      className="playButton"
                      onClick={() =>
                        navigateToWatch(animeId, animeTitle, episodeNum)
                      }
                    />
                  </div>

                  {/* ------Episode------ */}
                  <div className="custom_eps_container">
                    Episode {episodeNum}
                  </div>
                  {/* ------Img------ */}
                  <LazyLoadImage
                    width="100%"
                    height="210px"
                    className="recommendations_img"
                    src={animeImg}
                    style={{ borderRadius: "0" }}
                    alt="recommendation_img"
                  />
                </div>
                <h1
                  className="recommendations_title"
                  style={{
                    color: "whitesmoke",
                    paddingInline: "5px",
                    textAlign: animeTitle.length > 25 ? "left" : "center",
                  }}
                >
                  {animeTitle.length > 30
                    ? `${animeTitle.slice(0, 30)}...`
                    : animeTitle}
                </h1>
              </div>
            );
          })}
        </div>
        <FontAwesomeIcon
          id={data.showcase}
          icon={faChevronCircleUp}
          onClick={() => window.scrollTo(0, 0)}
        />
        {visible < data.currentlyReleasedEp.length && (
          <button
            id="load_more"
            onClick={() => data.handleLoad(10, setVisible, "increase")}
            style={{
              position: "relative",
              left: location.pathname === "/top-anime" && "50%",
              transform:
                location.pathname === "/top-anime" && "translateX(-50%)",
            }}
          >
            Load more
          </button>
        )}
      </>
    );
  }
});
export default CurrentLyReleasedEp;
