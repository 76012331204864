import { useContext } from "react";
import "./SearchHint.css";
import { ContextAPI } from "./animedb";
import { useNavigate } from "react-router";
import { matchSorter } from "match-sorter";
const SearchHint = () => {
  const contextData = useContext(ContextAPI);
  const navigate = useNavigate();
  const searchHintList = contextData.searchList.filter(
    (ele) => !ele.animeId.includes("dub")
  );
  if (contextData.isSearchLoading) {
    return (
      <div
        className="search_hint_wrapper"
        style={{ position: "relative", height: "300px" }}
      >
        <img
          src={require("./img/handLoading.gif")}
          alt="anime image"
          className="search_hint_gif"
        />
      </div>
    );
  }
  return (
    <div className="search_hint_wrapper" ref={contextData.searchHintRef}>
      {searchHintList.slice(0, 5).map((ele) => {
        const { animeImg, animeTitle, status, animeId } = ele;
        return (
          <div
            className="search_hint_container"
            onClick={async () => {
              const res = await fetch(
                `https://api.jikan.moe/v4/anime?q=${animeId.replace(
                  "-ova",
                  ""
                )}`
              );
              const result = await res.json();
              const list = matchSorter(
                result.data.filter((ele) => ele.approved === true),
                animeTitle.replace("OVA", ""),
                {
                  keys: [
                    {
                      maxRanking: matchSorter.rankings.STARTS_WITH,
                      key: "title",
                    },
                  ],
                }
              );
              const final_result = list.length > 0 ? list[0] : result.data[0];
              contextData.searchHintRef.current.style.display = "none";
              navigate(`/details/${final_result.mal_id}`, {
                state: {
                  result: final_result,
                  animeTitle: final_result.title,
                  animeTitleEnglish: final_result.title_english,
                },
              });
            }}
          >
            <img src={animeImg} alt="anime image" className="search_hint_img" />
            <div className="anime_info_wrapper">
              <h1 style={{ fontSize: "1rem", marginBottom: "10px" }}>
                {animeTitle}
              </h1>
              <h4 style={{ fontSize: "0.7rem" }} className="search_hint_status">
                {status}
              </h4>
            </div>
          </div>
        );
      })}
      {searchHintList && searchHintList.length > 0 ? (
        <button
          className="search_hint_button"
          onClick={() => {
            navigate("/search", { state: { searchList: searchHintList } });
            contextData.searchHintRef.current.style.display = "none";
          }}
        >
          View Full
        </button>
      ) : (
        <p id="no_search_result_text">No Results match your search!</p>
      )}
    </div>
  );
};
export default SearchHint;
