import ReactDOM from 'react-dom/client';
import App from './app';
import * as serviceWorker from './serviceWorkerRegistration'
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
serviceWorker.register();


