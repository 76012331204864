import { useNavigate } from "react-router";
import "./footer.css";
import {
  LazyLoadImage,
  trackWindowScroll,
} from "react-lazy-load-image-component";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <div className="footer_wrapper">
      <div className="donation_wrapper">
        <div className="logo_wrapper" onClick={() => navigate("/")}>
          <div className="logo_container">
            <LazyLoadImage
              className="footer_logo"
              src={require("./img/animedia_logo.webp")}
              alt="animedia_logo"
            />
          </div>
          <h1 className="footer_name">ANIMEDIA.me</h1>
        </div>
        <div className="donation_container">
          <div className="donation_card">
            <form
              action="https://www.paypal.com/donate"
              method="post"
              target="_blank"
            >
              <input type="hidden" name="business" value="JUQZMXYV4V7V6" />
              <input type="hidden" name="no_recurring" value="0" />
              <input
                type="hidden"
                name="item_name"
                value="Animedia is a ad-free website developed by me myself. Your donations could help me improve the website. Thank you!"
              />
              <input type="hidden" name="currency_code" value="USD" />
              <input
                className="paypal"
                type="image"
                src={require("./img/paypal.webp")}
                border="none"
                name="submit"
                title="PayPal - The safer, easier way to pay online!"
              />
            </form>
            <h2 className="text">Paypal</h2>
          </div>
        </div>
        <div className="contact_holder">
          <h2
            onClick={() => navigate("/contact")}
            className="contact_div"
            style={{
              marginRight: "20px",
            }}
          >
            Contact Us
          </h2>
          <h2 onClick={() => navigate("/terms")} className="contact_div">
            Terms of Service
          </h2>
        </div>
        <div className="footer_foot">
          <p style={{ color: "#A8A8A8", margin: "10px 0" }}>
            Animedia does not store any files on our server, we only linked to
            the media which is hosted on 3rd party services.
          </p>
          <p style={{ color: "#A8A8A8" }}>© animedia.me</p>
        </div>
      </div>
    </div>
  );
};
export default trackWindowScroll(Footer);
