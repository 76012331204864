import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useState, lazy, Suspense, useEffect } from "react";
import { useMediaQuery } from "react-responsive";
import NavBar from "./navbar";
import AnimeDB from "./animedb";
import AnimeDisplay from "./animedisplay";
import ErrorPage from "./error";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
// import AnimeSearch from "./animesearch";
// import ContactPage from "./contactPage";
// import TermsPage from "./termsPage";
// import Detail from "./animedetail";
// import WatchAnime from "./watch";
// import TraceImg from "./traceImg";
// import GenreSearch from "./searchByGenres";
// import ResetPasswordPage from "./resetPasswordPage";
// import Profile from "./profile";
// import User from "./User";
// import TopAnime from "./topanime";
// import Authentication from "./authentication";
import Footer from "./footer";
import { ChooseAvatar } from "./authentication";
import { getAuth } from "firebase/auth";
import { app } from "./firebase.config";
import { Modal, Box } from "@mui/material";
function App() {
  const Authentication = lazy(() => import("./authentication"));
  const AnimeSearch = lazy(() => import("./animesearch"));
  const Detail = lazy(() => import("./animedetail"));
  const WatchAnime = lazy(() => import("./watch"));
  const TermsPage = lazy(() => import("./termsPage"));
  const ContactPage = lazy(() => import("./watch"));
  const TraceImg = lazy(() => import("./traceImg"));
  const GenreSearch = lazy(() => import("./searchByGenres"));
  const ResetPasswordPage = lazy(() => import("./resetPasswordPage"));
  const Profile = lazy(() => import("./profile"));
  const User = lazy(() => import("./User"));
  const TopAnime = lazy(() => import("./topanime"));
  const auth = getAuth(app);
  const [user, setUser] = useState();
  const [open, setOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const mobile_screen = useMediaQuery({ query: "(max-width: 768px)" });
  useEffect(() => {
    auth.onAuthStateChanged((user) => setUser(user));
  }, []);
  const AuthenticationModal = () => {
    return (
      <>
        <Modal
          style={{
            width: mobile_screen ? "90%" : "550px",
            position: "absolute",
            transform: "translate(-50%, -50%)",
            top: "40%",
            left: "50%",
          }}
          open={open}
          onClose={() => setOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{ position: "relative" }}>
            <Suspense>
              <Authentication setOpen={setOpen} setIsOpen={setIsOpen} />
            </Suspense>
            <button
              onClick={() => setOpen(false)}
              style={{
                cursor: "pointer",
                position: "fixed",
                top: "-15px",
                right: mobile_screen ? "-5px" : "-10px",
                display: "inline-block",
                width: "fit-content",
                height: "fit-content",
              }}
            >
              <FontAwesomeIcon
                onClick={() => setOpen(false)}
                icon={faCircleXmark}
                style={{
                  borderRadius: "50%",
                  fontSize: "35px",
                  cursor: "pointer",
                  background: "blue",
                }}
              />
            </button>
          </Box>
        </Modal>
        <Modal
          hideBackdrop
          style={{
            width: mobile_screen ? "90%" : "500px",
            position: "absolute",
            transform: "translate(-50%,-50%)",
            top: "40%",
            left: "50%",
            margin: "0 auto",
          }}
          open={isOpen}
          onClose={() => setIsOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={{ position: "relative" }}>
            <ChooseAvatar
              setIsOpen={setIsOpen}
              name={user && user.displayName}
              user={user && user}
              uid={user && user.uid}
            />
            <button
              onClick={() => setIsOpen(false)}
              style={{
                cursor: "pointer",
                position: "absolute",
                top: "-15px",
                right: mobile_screen ? "-5px" : "-10px",
                display: "inline-block",
                width: "fit-content",
              }}
            >
              <FontAwesomeIcon
                icon={faCircleXmark}
                style={{ fontSize: "35px" }}
              />
            </button>
          </Box>
        </Modal>
      </>
    );
  };
  return (
    <AnimeDB setOpen={setOpen}>
      <Router>
        <AuthenticationModal />
        <NavBar user={user} setOpen={setOpen} />
        <Routes>
          <Route path="/*" element={<ErrorPage />} status={404} />
          <Route
            exact
            path="/"
            element={<AnimeDisplay user={user} setOpen={setOpen} />}
          />
          <Route
            exact
            path="/search"
            element={
              <Suspense>
                <AnimeSearch />
              </Suspense>
            }
          />
          <Route
            path="/top-anime"
            element={
              <Suspense>
                <TopAnime user={user} setOpen={setOpen} />
              </Suspense>
            }
          />
          <Route
            path="/details/:id"
            element={
              <Suspense>
                <Detail />
              </Suspense>
            }
          />
          <Route
            path="/search-by-image"
            element={
              <Suspense>
                <TraceImg />
              </Suspense>
            }
          />
          <Route
            path="/search-by-genres"
            element={
              <Suspense>
                <GenreSearch />
              </Suspense>
            }
          />
          <Route
            path="/watch/:name/:id"
            element={
              <Suspense>
                <WatchAnime
                  user={user}
                  setIsOpen={setIsOpen}
                  setOpen={setOpen}
                />
              </Suspense>
            }
          />
          <Route
            path="/user/profile"
            element={
              <Suspense>
                <Profile user={user} setOpen={setOpen} setIsOpen={setIsOpen} />
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense>
                <ResetPasswordPage setOpen={setOpen} />
              </Suspense>
            }
          />
          <Route
            path="/user/:uid"
            element={
              <Suspense>
                <User user={user} setOpen={setOpen} />
              </Suspense>
            }
          />
          <Route
            path="/contact"
            element={
              <Suspense>
                <ContactPage />
              </Suspense>
            }
          />
          <Route
            path="/terms"
            element={
              <Suspense>
                <TermsPage />
              </Suspense>
            }
          />
        </Routes>
        <Footer />
      </Router>
    </AnimeDB>
  );
}
export default App;
