import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useReducer,
} from "react";
import "./animedb.css";
import {
  getAuth,
  setPersistence,
  browserLocalPersistence,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { app } from "./firebase.config";
import axios from "axios";
export const ContextAPI = React.createContext();
const AnimeDB = (props, { setOpen, user }) => {
  const [searchAnime, setSearchAnime] = useState("");
  const [navBar, setNavBar] = useState("nav");
  const [gif, setGif] = useState("gif_up");
  const [isSearchLoading, setIsSearchLoading] = useState();
  const [searchList, setSearchList] = useState([]);
  const [showcase, setShowCase] = useState("hide");
  const [caughtError, setcaughtError] = useState(null);
  const [forgotPassWordEmail, setForgotPassWordEmail] = useState();
  const searchHintRef = useRef();
  const url = "https://api.jikan.moe/v4";
  const random_quote_url = "https://animechan.vercel.app/api/random";
  const auth = getAuth(app);
  const provider = new GoogleAuthProvider();
  const getUser = (type) => {
    setPersistence(auth, browserLocalPersistence)
      .then(() => {
        signInWithPopup(auth, provider);
      })
      .then(() => {
        auth.onAuthStateChanged((user) => {
          switch (type) {
            case "login":
              break;
            case "register":
              setOpen(true);
          }
        });
      });
  };
  const findWindowScroll = useCallback(() => {
    const windowPosition = window.pageYOffset;
    if (windowPosition >= 450) {
      setShowCase("showcase");
    } else {
      setShowCase("hide");
    }
  }, [url]);
  const handleWindow = () => {
    window.scrollTo(0, 0);
  };
  const initialState = {
    isLoading: "true",
    randomQuote: "",
    animeList: [],
    topAnime: [],
    trendingList: [],
    currentlyReleasedEp: "",
    randomInfo: "",
    randomIdMal: "",
    randomCharImg: "",
    randomAnimeImg: "",
    randomDescription: "",
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "all":
        const pl = action.payload;
        return {
          ...state,
          isLoading: false,
          trendingList:
            pl[4].value &&
            pl[4].value.data.data.MediaListCollection.lists[0].entries,
          randomQuote: pl[3].value && pl[3].value.data,
          animeList: pl[0].value && pl[0].value.data.data,
          topAnime: pl[1].value && pl[1].value.data.top,
          currentlyReleasedEp: pl[2].value && pl[2].value.data,
        };
      // case "random":
      //   const r_pl = action.payload;
      //   return {
      //     ...state,
      //     randomInfo: r_pl.data,
      //     randomIdMal: r_pl.data.Character.media.nodes[0].idMal,
      //     randomCharImg: r_pl.data.Character.image.medium,
      //     randomAnimeImg: r_pl.data.Character.media.nodes[0].bannerImage,
      //     randomDescription: r_pl.data.Character.media.nodes[0].description,
      //     isLoading: false,
      //   };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const getAnime = useCallback(async () => {
    const trendingQuery = `
      query($userName: String) {
        MediaListCollection(userName: $userName, type: ANIME){
          lists  {
            entries {
               media {
                idMal
          title {
            romaji
            english
          }
          format         
          description     
          duration          
          bannerImage
          genres
          type
          characters {
      edges {
        role
        node {
          name {
            full 
          }
          image {
            medium
          }
          siteUrl
        }   
        voiceActors {
          name {
            full
          }
          image {
            medium
          }
          languageV2 
          siteUrl
        }
      }
    }
     streamingEpisodes {
      title
      thumbnail
    }
          recommendations{
            nodes{
              mediaRecommendation {
                idMal 
                title { romaji, english }
                format
                status
                episodes
                duration
                coverImage { medium }
                genres 
              }
            }
        }
              }
            }
          }
        }
      }
    `;
    const trendingVariables = {
      userName: "tdattran1707",
    };
    const res = await Promise.allSettled([
      axios.get(`${url}/seasons/now`),
      axios.get("https://api.jikan.moe/v3/top/anime/1/bypopularity"),
      axios.get("https://gogoanime.herokuapp.com/recent-release"),
      axios.get(random_quote_url),
      axios("https://graphql.anilist.co", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: JSON.stringify({
          query: trendingQuery,
          variables: trendingVariables,
        }),
      }),
    ]);
    dispatch({ type: "all", payload: res });
    // const query = `
    //   query ($search: String) {
    //     Character(search: $search){
    //       image{
    //         medium
    //       }
    //       media {
    //         nodes{
    //           idMal
    //           title {
    //             romaji
    //             english
    //           }
    //           bannerImage
    //           description
    //           episodes
    //         }
    //       }
    //     }
    //   }
    //   `;
    // if (
    //   res[3] &&
    //   res[3].value &&
    //   res[3].value.data &&
    //   res[3].value.data.character
    // ) {
    //   const character = res[3].value.data.character;
    //   const variables = { search: character };
    //   const random_res = await axios("https://graphql.anilist.co", {
    //     method: "POST",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //     },
    //     data: JSON.stringify({
    //       query: query,
    //       variables: variables,
    //     }),
    //   });
    //   const random_result = random_res.data;
    //   dispatch({ type: "random", payload: random_result });
    // }
  }, []);
  useEffect(() => {
    getAnime();
  }, []);
  const ShowHint = searchAnime.length >= 3 ? true : false;
  const FindSearchAnime = async () => {
    setcaughtError(null);
    setIsSearchLoading(true);
    try {
      let cancelToken;
      if (typeof cancelToken !== typeof undefined) {
        cancelToken.cancel("canceling the previous req");
      }
      cancelToken = axios.CancelToken.source();
      const res = await axios.get(
        // `https://api.jikan.moe/v4/anime?q=${searchAnime}&sfw=true`
        `https://medianime.herokuapp.com/search?keyw=${searchAnime}`,
        { cancelToken: cancelToken.token }
      );
      const result = res.data;
      if (res.statusText !== "OK") {
        throw Error("No results match your search!");
      }
      const filtered_list = result.filter(({ status }) => {
        const regrex = /\d+/g;
        let matches = status.match(regrex); //matches is a list containing numbers after the word "Released: "
        // return those that will not be released further years
        return matches - new Date().getFullYear() < 1 && status !== "";
      });
      setcaughtError(
        filtered_list.length === 0 ? "No results match your search" : null
      );
      setSearchList(filtered_list);
      setIsSearchLoading(false);
    } catch (error) {
      setIsSearchLoading(false);
      setcaughtError(error.message);
    }
  };
  const handleSearch = (e) => {
    e.preventDefault();
    FindSearchAnime();
  };

  const handleNavBar = () => {
    if (navBar === "nav") {
      setNavBar("show_nav");
      setGif("gif_down");
    } else {
      setNavBar("nav");
      setGif("gif_up");
    }
  };
  const handleLoad = (number, setVisble, operation) => {
    switch (operation) {
      case "increase":
        setVisble((prev) => prev + number);
        break;
      case "decrease":
        setVisble((prev) => prev - number);
        break;
    }
  };
  const randomQuote = state.randomQuote;
  const randomInfo = state.randomInfo;
  // const randomIdMal = state.idMal;
  // const randomAnimeImg = state.randomAnimeImg;
  // const randomCharImg = state.randomCharImg;
  // const randomDescription = state.randomDescription;
  const topAnime = state.topAnime;
  const currentlyReleasedEp = state.currentlyReleasedEp;
  const animeList = state.animeList;
  const isLoading = state.isLoading;
  const trendingList = state.trendingList;
  return (
    <ContextAPI.Provider
      value={{
        navBar,
        handleNavBar,
        randomQuote,
        randomInfo,
        // randomIdMal,
        // randomAnimeImg,
        // randomCharImg,
        // randomDescription,
        searchAnime,
        setSearchAnime,
        setSearchList,
        searchHintRef,
        ShowHint,
        handleSearch,
        handleLoad,
        searchList,
        isSearchLoading,
        currentlyReleasedEp,
        topAnime,
        trendingList,
        isLoading,
        gif,
        animeList,
        showcase,
        findWindowScroll,
        handleWindow,
        caughtError,
        user,
        getUser,
        setForgotPassWordEmail,
        forgotPassWordEmail,
        FindSearchAnime,
      }}
    >
      {props.children}
    </ContextAPI.Provider>
  );
};
export default AnimeDB;
