import { useNavigate } from "react-router";
import "./error.css";
const ErrorPage = () => {
  const navigate = useNavigate();
  return (
    <div className="error_container">
      <img
        className="error_img"
        src={require("./img/page_not_found.webp")}
        alt="error image"
      />
      <button className="error_btn" onClick={() => navigate("/")}>
        Take me Home!
      </button>
    </div>
  );
};
export default ErrorPage;
